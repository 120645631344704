import { createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, { locale: $setup.getAntdLocale }, {
    default: _withCtx(() => [
      _createCommentVNode(" vue3.0配置 "),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_ctx.$route.path === '/bind')
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
              : _createCommentVNode("v-if", true)
          ], 1024 /* DYNAMIC_SLOTS */)),
          (_ctx.$route.path !== '/bind')
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"]))
}